 <template>
<div id="page-branch-list">
    <div class="vx-card p-6">
        <div class="vx-row items-center mb-2">
            <div class="vx-col md:w-1/2">
                <TitleScreen></TitleScreen>
            </div>
            <div class="vx-col md:w-1/2">
                <vs-button
                    class="float-right ml-5"
                    @click="addHeatmap"
                    icon="icon-plus"
                    icon-pack="feather"
                    color="primary">
                    {{ $t('button.addNew') }}
                </vs-button>
            </div>
        </div>
        <div class="vx-row items-center">
            <div class="vx-col md:w-1/4 sm:w-auto">
                <label class="vs-input--label">{{$t('menuIntrusion.branch')}}</label>
                <v-select class="" v-model="selectedOrganizationBranch"
                          :options="organizationBranchOptions">
                    <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                </v-select>
            </div>
            <div class="vx-col md:w-3/4 float-right">
                <vs-input class="md:w-1/4 float-right"
                          v-model="searchQuery"
                          @input="updateSearchQuery" :placeholder="$t('button.search')"/>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <ag-grid-vue
                            ref="agGridTable"
                            :components="components"
                            :gridOptions="gridOptions"
                            class="ag-theme-material w-100 my-4 ag-grid-table"
                            :columnDefs="columnDefs"
                            :defaultColDef="defaultColDef"
                            :rowData="heatmapList"
                            :animateRows="true"
                            :pagination="true"
                            :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                            :paginationPageSize="paginationPageSize"
                            :onGridReady="onGridReady"
                            :suppressPaginationPanel="true">
                </ag-grid-vue>
            </div>
        </div>
        <div class="vx-row items-center">
            <div class="vx-col md:w-1/2">
                <Pagination
                    :itemList="heatmapList"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />
            </div>
            <div class="vx-col md:w-1/2">
                <vs-pagination
                    :total="totalPages"
                    :max="maxPageNumbers"
                    v-model="currentPage"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import moment from 'moment';
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        vSelect,
        AgGridVue,
        Pagination,
        CellRendererStatus,
        CellRendererActions,
        TitleScreen
    },
    data(){
        return {
            heatmapList: [],
            //optional
            organizationBranchOptions: [],
            valueSelectedBranch: null,

            maxPageNumbers: 7,

            //search
            searchQuery: "",
            components:{
                vSelect,
                AgGridVue,
                Pagination
            },
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('heatmap.heatMapName'),
                    field: 'name',
                    width: 100
                },
                {
                    headerName: this.$t('heatmap.branch'),
                    field: 'branchName',
                    width: 120
                },
                {
                    headerName: this.$t('heatmap.cameraName'),
                    field: 'cameraName',
                    width: 70
                },
                {
                    headerName: this.$t('heatmap.creator'),
                    field: 'modifiedBy',
                    width: 80,
                },
                {
                    headerName: this.$t('heatmap.createdDate'),
                    field: 'createdAt',
                    width: 60,
                },
                {
                    headerName: this.$t('heatmap.action'),
                    field: 'actions',
                    width: 100,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
        }
    },
    computed: {
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.valueSelectedBranch, this.organizationBranchOptions),
                    value: this.valueSelectedBranch
                }
            },
            set(obj) {
                this.valueSelectedBranch = obj ? obj.value : null;
                this.getListHeatmap();
            }
        }
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateHeatMap'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_HEAT_MAP', false);
            }
        }
    },
    methods: {
        getListHeatmap: function(){
            const payload = {
                organizationBranchId: this.valueSelectedBranch
            }
            this.$vs.loading();
            this.$crm.post('/heatmap/find-by-condition', payload).then((res) => {
                let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
                this.heatmapList = res.data.map(el => {
                    let camera = this.cameraOptions.find(cam => cam.id == el.cameraId);
                    el.cameraName = camera.cameraName
                    el.createdAt = moment(el.createdAt).format('DD/MM/YYYY');
                    branchList.forEach(branch => {
                        if (el.organizationBranchId == branch.id) el.branchName = branch.organizationBranchName;
                    })
                    return el;
                });
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        addHeatmap: function(){
            this.$router.push('/user/add-heat-map-details').catch(() => {});
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        updateSearchQuery: function(val){
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id, data) {
            if (!id) this.valueSelectedBranch = id;
            let organizationBranch = data.find(x => x.value == id);
            return (organizationBranch && organizationBranch.label) || 'Chưa xác định';
        },
        getCamera() {
            return new Promise(resolve => {
                let url = '/camera/all';
                this.$crm.post(url).then((response) => {
                    if (response.data.length > 0) {
                        this.cameraOptions = response.data;
                    }
                    resolve(true);
                }).catch((err) => {
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            if (branchList.length === 0) return;
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            this.organizationBranchOptions.unshift({value: null, label: this.$t('menuIntrusion.all')})
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];

        },
    },
    async created(){
        await this.getCamera();
        this.initScreen();
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style>

</style>
